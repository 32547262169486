import React from 'react'
import SectionTitle from '../../elements/section-title/SectionTitle';
import ServicePropOne from './ServicePropOne';
export const ServiceDark = () => {
  return (
    <div className="section section-padding-2 bg-color-light-blue">
        <div className="container">
            <SectionTitle 
                title="Services we can help you with"
                textAlignment="heading-light-left"
                textColor=""
            />
            <div className='row'>
                <ServicePropOne colSize="col-xl-6 col-md-12" serviceStyle="" itemShow="6" />
            </div>
        </div>
        <ul className="list-unstyled shape-group-10">
            <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
            <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
        </ul>
    </div>
  )
}
