import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedin } from "react-icons/fa";

const Footer = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><Link to="https://facebook.com/linearlabs"><FaFacebookF /></Link></li>
                            <li><Link to="https://twitter.com/linearlabs"><FaTwitter /></Link></li>
                            <li><Link to="https://www.linkedin.com/company/linearlabs"><FaLinkedin /></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://linearlabs.tech/">Linear Labs Pty Ltd</a>.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;